.navbar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
    background-color: #333;
    color: white;
  }
  
  .navbar-toggle {
    display: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .navbar-links {
    display: flex;
    gap: 20px;
  }
  
  .navbar-links a {
    color: white;
    text-decoration: none;
  }
  
  @media (max-width: 768px) {
    .navbar-toggle {
      display: block;
    }
  
    .navbar-links {
      display: none;
      flex-direction: column;
      width: 100%;
      background-color: #333;
      position: absolute;
      top: 50px;
      left: 0;
    }
  
    .navbar-links.active {
      display: flex;
    }
  }