* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  background-color: rgb(43, 42, 42);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.navbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  flex-direction: row;
}

.navbar a {
  color: #f1f1f1;
  text-decoration: none;
  padding: 10px 20px;
  font-family: 'Oswald', sans-serif;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  border-radius: 5px;
}

.navbar a:hover {
  color: #41cbee;
}

.navbar a.chat {
  font-weight: semibold;
  transition: background-color 0.3s ease;
}

.projects-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(43, 42, 42);
}

.project {
  background-color: rgb(30, 30, 30);
  color: rgb(195, 195, 195);
  margin-top: 30px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
}

.project:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.project-name {
  font-size: 24px;
  font-weight: bold;
  color: rgb(89, 173, 133);
  margin-bottom: 10px;
}

.project-description {
  font-size: 16px;
  margin-bottom: 10px;
}

.project-technologies {
  list-style-type: none;
  padding: 0;
  margin: 0 0 10px 0;
}

.technology-item {
  display: inline-block;
  background-color: rgb(43, 42, 42);
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
}

.button-github {
  display: inline-block;
  background-color: rgb(30, 30, 30);
  border-radius: 30vh;
  color: rgb(195, 195, 195);
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  color: #59ad85;
}

.button-project {
  display: inline-block;
  background-color: rgb(30, 30, 30);
  border-radius: 30vh;
  color: rgb(195, 195, 195);
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  color: #59ad85
}

.button-github:hover {
  background-color: #3d3e40;
  transform: translateY(-3px);
}

.button-project:hover {
  background-color: #3d3e40;
  transform: translateY(-3px);
}

.nav {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.nav a {
  font-size: 1.2rem;
  color: #adafb4;
  text-decoration: none;
  padding: 10px 10px;
}

.nav a:hover {
  color: #41cbee;
}

.nav a:active {
  color: white;
}

@keyframes navFadeIn {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav a {
  font-size: 1.2rem;
  color: #adafb4;
  text-decoration: none;
  padding: 0px 10px;
  opacity: 0;
  animation: navFadeIn 1s ease forwards;
  animation-delay: 0.5s;
}

.nav a:nth-child(2) {
  animation-delay: 0.7s;
}

.nav a:hover {
  color: #41cbee;
}

.nav a:active {
  color: white;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
  }

  .navbar a {
    margin: 3px 0;
    text-align: center;
  }
}

.header-area {
  width: 100%;
  height: 100vh;
  background-color: rgb(43, 42, 42);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.header {
  width: 100%;
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(30, 30, 30);
  opacity: 1;
  clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
}

.head-title {
  font-size: 5.5vh;
  padding: 20%;
  font-weight: bold;
  color: rgb(195, 195, 195);
  text-align: center;
  font-family: "Oswald", serif;
  margin: 0;
  padding: 0;
  animation: text 1s ease-in-out;
  line-height: 1.5em;
}

@keyframes text {
  0% {
    opacity: 0;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media (max-width: 1024px) {
  .head-title {
    font-size: 3.5vh;
  }

  .courses-list,
  .projects-list {
    padding: 0.5vh;
  }
}

.course {
  background-color: rgb(30, 30, 30);
  color: rgb(195, 195, 195);
  margin-top: 30px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
}

.course:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.courses-list,
.projects-list {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

ul.course-technologies>* {
  margin: 5px;
}

.course-name {
  font-size: 24px;
  font-weight: bold;
  color: rgb(89, 173, 133);
  margin-bottom: 10px;
}

.course-description {
  font-size: 16px;
  margin-bottom: 10px;
}

.course-technologies {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.technology-item {
  display: inline-block;
  background-color: rgb(43, 42, 42);
  padding: 5px 10px;
  margin-right: 5px;
  margin-top: 5px;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
}

.footer {
  margin-top: 25px;
  background-color: rgb(30, 30, 30);
  padding: 20px 0;
  text-align: center;
  color: #f1f1f1;
}

.footer-content {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-link {
  color: #f1f1f1;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  /* Spatiu între icon și text */
}

.footer-link:hover {
  color: #41cbee;
}